import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Stage from '../components/Stage';
import Section from '../components/Section';
import Button from '../components/Button';
import { Headline } from '../components/Typography';

const ThankYouPage = ({ data }) => {
    const { stageImage } = data;
    return (
        <Layout title="Kontakt">
            <Stage image={stageImage.childImageSharp} title="Kontakt" />
            <Section container="m">
                <Headline level="h2" gap="l">
                    Vielen Dank für Ihre Nachricht, wir melden uns zeitnah bei Ihnen!
                </Headline>
                <Button to="/">Zurück zur Startseite</Button>
            </Section>
        </Layout>
    );
};

ThankYouPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        stageImage: file(relativePath: { glob: "about-us/stage-image.jpg" }) {
            ...StageFluid
        }
    }
`;

export default ThankYouPage;
